import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Logo from './Logo';
import handleOpenApp from "utils/handleOpenApp"

const Button = ({ to, label, onClick, variant = 'outline', fullWidth = false, isActive, setIsMenuOpen }) => {
  const baseClasses = `btn ${variant ? 'btn-' + variant : 'btn-outline'} ${fullWidth ? 'w-full' : ''} ${isActive ? 'btn-active' : ''}`;

  const handleClick = () => {
    if (onClick) onClick();
    if (setIsMenuOpen) setIsMenuOpen(false);
  };

  return (
    <Link to={to} onClick={handleClick} className="relative">
      <button className={baseClasses}>
        {label}
      </button>
    </Link>
  );
};

const MenuButton = ({isMenuOpen, setIsMenuOpen})=>{
    return(
        <button
            className="block md:hidden text-gray-800 focus:outline-none relative"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
            <div className="flex flex-col justify-between w-[26px] h-[20px] relative">
                <span
                className={`block h-[2px] bg-current transition-transform duration-300 ease-in-out transform transform-origin-center ${
                    isMenuOpen ? 'rotate-45 translate-y-[9px]' : ''
                }`}
                />
                <span
                className={`block h-[2px] bg-current transition-opacity duration-300 ease-in-out ${
                    isMenuOpen ? 'opacity-0' : ''
                }`}
                />
                <span
                className={`block h-[2px] bg-current transition-transform duration-300 ease-in-out transform transform-origin-center ${
                    isMenuOpen ? '-rotate-45 -translate-y-[9px]' : ''
                }`}
                />
            </div>
        </button>
    )
}
const Header = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const location = useLocation();

    const menuItems = <>
        <Button to="/about" label="About Us" fullWidth variant="" isActive={location.pathname === '/about'} setIsMenuOpen={setIsMenuOpen} />
        <Button to="/blog" label="Blog" fullWidth variant="" isActive={location.pathname.includes('/blog')} setIsMenuOpen={setIsMenuOpen} />
        <Button to="/faq" label="FAQ" fullWidth variant="" isActive={location.pathname.includes('/faq')} setIsMenuOpen={setIsMenuOpen} />
        <Button onClick={handleOpenApp} label="Start" fullWidth variant="primary" setIsMenuOpen={setIsMenuOpen} />
    </>

  return (
    <div className="bg-white shadow z-40 sticky top-0">
      <div className="max-w-7xl mx-auto px-4 py-6 flex justify-between items-center">
        <div className="relative">
          <Link to="/" onClick={() => setIsMenuOpen(false)} className="cursor-pointer">
            <Logo />
          </Link>
        </div>

        {/* Hamburger icon with animation */}
        <MenuButton isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen}/>

        {/* Desktop menu */}
        <div className="hidden md:flex space-x-4">{menuItems}</div>

        {/* Mobile menu */}
        <div
          className={`md:hidden absolute bottom-1 translate-y-full left-0 right-0 bg-white shadow-lg z-10 transition-max-height duration-500 ease-in-out overflow-hidden ${
            isMenuOpen ? 'max-h-[1000px] p-4' : 'max-h-0'
          }`}
        >
          <div className="flex flex-col items-center space-y-4">{menuItems}</div>
        </div>
      </div>
    </div>
  );
};

export default Header;

import React from 'react';
import { Link } from 'react-router-dom';
import posts from './posts';

const Post = ({post})=>{
    return(
        <Link to={`/blog/${post.slug}`}>
            <div className="relative bg-white shadow-md rounded-lg p-6 mb-8">
                <img src={post.img} className='w-full rounded-[inherit] mb-5'/>
                <h2 className="text-3xl font-semibold text-gray-900 mb-4">
                    {post.title}
                </h2>
                <p className="text-lg text-gray-700 mb-4">{post.excerpt}</p>   
                <button className="btn btn-primary btn-lg px-6 py-3 font-bold rounded-full shadow-lg hover:scale-105 transition-transform duration-300 ease-in-out">
                    Read More
                </button>  
            </div>
        </Link>
    )
}
const BlogPage = () => {
  return (
    <div className="bg-gray-50 py-16 px-6 lg:px-24">
      <div className="max-w-3xl mx-auto space-y-12">
        <h1 className="text-5xl font-extrabold text-blue-600 text-center mb-12">
          Ride Share Blog
        </h1>
        {posts.map((post, index) => <Post key={index} post={post}/>)}
      </div>
    </div>
  );
};

export default BlogPage;

// ReadNext.js
import React from 'react';
import { Link } from 'react-router-dom';
import posts from './posts'; // Import posts array

const Post = ({post})=>{
    return(
        <Link to={`/blog/${post.slug}`} className="flex-1 md:max-w-xs">
            <div className="relative bg-white shadow-md rounded-lg p-4 min-h-[200px] h-full flex flex-col justify-between transition-transform transform hover:scale-105">
                <img src={post.img} className='w-full rounded-[inherit] mb-5'/>
                <h4 className="text-xl font-semibold text-blue-600 mb-2">{post.title}</h4>
                <p className="text-gray-700 mb-4">{post.excerpt}</p>
            </div>
        </Link>
    )
}

const ReadNext = ({ currentIndex }) => {
    // Calculate the next 3 post indices and wrap around if necessary
    const nextPosts = [
        posts[(currentIndex + 1) % posts.length],
        posts[(currentIndex + 2) % posts.length],
        posts[(currentIndex + 3) % posts.length]
    ];

    return (
        <div className="text-center py-8">
            <h3 className="text-2xl font-bold text-gray-900 mb-6">Read Next</h3>
            <div className="flex flex-col md:flex-row md:justify-center md:space-x-6 space-y-6 md:space-y-0">
                {nextPosts.map((post, index) => <Post key={index} post={post}/>)}
            </div>
        </div>
    );
};

export default ReadNext;

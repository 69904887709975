import handleOpenApp from 'utils/handleOpenApp';
import rideImg from 'assets/images/land/ride.jpg';

const CTARide = () => (
  <div className="relative w-full h-[50vh] flex items-center justify-center" style={{
    backgroundImage: `url(${rideImg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  }}> 
    <div className='relative'>
      <div className="relative z-10 text-center text-white">
        <h2 className="text-4xl font-bold mb-4">Ready to Find Your Next Ride?</h2>
        <p className="text-2xl font-semibold mb-8">Join a network of travelers sharing rides, reducing costs, and making new connections.</p>
        <div onClick={handleOpenApp}>
          <button className="btn btn-warning btn-lg px-8 py-4 font-bold rounded-full shadow-lg hover:scale-105 transition-transform duration-300 ease-in-out">
            Start Your Ride Today!
          </button>
        </div>
      </div>
      <div className="absolute inset-0 bg-blue-950 opacity-50 blur-2xl"/>
    </div>
    <div className="absolute inset-0 bg-blue-950 opacity-50"/>
  </div>
);

export default CTARide;

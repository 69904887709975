import React from 'react';
import HeroSection from './components/HeroSection';
import TextSection from './components/TextSection';
import HowItWorks from './components/HowItWorks';
import PlanetSection from './components/PlanetSection';
import AboutUsSection from './components/AboutUsSection';
import BlogSection from './components/BlogSection';
import CTARide from './components/CTARide';

const Land = () => {
  return (
    <div className="bg-gray-50">
      <HeroSection />
      <TextSection/>
      <HowItWorks />
      <PlanetSection />
      <div className='flex flex-col md:flex-row'>
        <AboutUsSection />
        <BlogSection />
      </div>
      <CTARide />
    </div>
  );
};

export default Land;

// posts.js
import cover1 from 'pages/Blog/posts/1/cover.webp';
import cover2 from 'pages/Blog/posts/2/cover.webp';
import cover3 from 'pages/Blog/posts/3/cover.webp';
import cover4 from 'pages/Blog/posts/4/cover.webp';
import cover5 from 'pages/Blog/posts/5/cover.webp';
import cover6 from 'pages/Blog/posts/6/cover.webp';
import cover7 from 'pages/Blog/posts/7/cover.webp';
import cover8 from 'pages/Blog/posts/8/cover.webp';
import cover9 from 'pages/Blog/posts/9/cover.webp';
import cover10 from 'pages/Blog/posts/10/cover.webp';

const posts = [
    {
        img: cover1,
      title: "The Ultimate Guide to Ride Sharing for Travelers",
      excerpt: "Discover how ride sharing can transform your travel experience, from saving money to reducing your carbon footprint. Learn how to find ride-sharing companions and make your trips more meaningful.",
      slug: "the-ultimate-guide-to-ride-sharing",
    },
    {
        img: cover2,
      title: "5 Reasons Why Ride Sharing is the Future of Travel",
      excerpt: "Explore why ride sharing is becoming the go-to solution for affordable, eco-friendly travel. Discover the key benefits of ride sharing and how it can change the way you travel.",
      slug: "5-reasons-why-ride-sharing-is-the-future",
    },
    {
        img: cover3,
      title: "How Ride Sharing Helps You Save Money on Long-Distance Trips",
      excerpt: "Long-distance trips can be expensive, but ride sharing offers an affordable alternative. Find out how you can cut costs on fuel, tolls, and more by sharing your ride.",
      slug: "how-ride-sharing-saves-money",
    },
    {
        img: cover4,
        title: "5 Tips for Planning a Shared Ride for Your Next Vacation",
        excerpt: "Planning a vacation? Learn five practical tips for organizing a shared ride, from posting your trip details early to choosing the right ride-sharing platform.",
        slug: "5-tips-for-planning-a-shared-ride-for-vacation",
    },
    {
        img: cover5,
        title: "How to Find Travel Companions for Long-Distance Rides",
        excerpt: "Looking for travel companions for a long-distance ride? Learn how to use ride-sharing apps to find the perfect travel partners, share costs, and make your journey more enjoyable.",
        slug: "how-to-find-travel-companions-for-long-distance-rides",
      },
      {
        img: cover6,
        title: "The Eco-Friendly Benefits of Ride Sharing",
        excerpt: "Discover how ride sharing reduces carbon emissions, conserves resources, and promotes sustainable travel. Learn the eco-friendly benefits of sharing rides for your next trip.",
        slug: "eco-friendly-benefits-of-ride-sharing",
      },
      {
        img: cover7,
        title: "How to Stay Safe While Ride Sharing",
        excerpt: "Learn how to stay safe while using ride-sharing services with these practical tips. From choosing a trusted platform to securing your belongings, here's how to protect yourself during a shared ride.",
        slug: "how-to-stay-safe-while-ride-sharing",
      },
      {
        img: cover8,
        title: "The Best Ride-Sharing Apps for Travelers",
        excerpt: "Discover the top ride-sharing apps for travelers. From long-distance trips to city commutes, these platforms offer affordable, convenient, and eco-friendly travel options.",
        slug: "best-ride-sharing-apps-for-travelers",
      },
      {
        img: cover9,
        title: "How Ride Sharing Reduces Your Carbon Footprint",
        excerpt: "Learn how ride sharing helps reduce carbon emissions, lower fuel consumption, and promote eco-friendly travel habits. Discover how you can minimize your environmental impact by sharing rides.",
        slug: "how-ride-sharing-reduces-your-carbon-footprint",
      },
      {
        img: cover10,
        title: "Ride Sharing vs. Car Rentals: Which is Better for Travelers?",
        excerpt: "Trying to decide between ride sharing or renting a car for your next trip? This post compares the cost, convenience, and environmental impact of both options to help you choose the best travel option.",
        slug: "ride-sharing-vs-car-rentals-which-is-better",
      }
]

export default posts;

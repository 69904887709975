import handleOpenApp from 'utils/handleOpenApp';
import planetImg from 'assets/images/land/planet.svg';

const PlanetSection = () => (
  <div className="bg-green-100 py-24">
    <div className="max-w-7xl mx-auto px-4">
      <h2 className="text-4xl font-bold text-center text-green-700 mb-12">Help the Planet, One Ride at a Time</h2>
      <div className="flex flex-col md:flex-row items-center gap-12">
        <div className="flex-1">
          <img src={planetImg} alt="Save the planet" className="w-full h-auto object-contain" />
        </div>
        <div className="flex-1 text-center md:text-left">
          <p className="text-lg text-gray-700 mb-4">
            Every shared ride reduces traffic and cuts down on harmful carbon emissions. By choosing to carpool, you’re contributing to a more sustainable, eco-friendly future.
          </p>
          <p className="text-lg text-gray-700 mb-4">
            Fewer cars on the road means cleaner air and a smaller environmental footprint. Join Ride Share and become part of a growing community making a positive impact.
          </p>
          <div onClick={handleOpenApp}>
            <button className="btn btn-success text-white btn-lg px-8 py-4 mt-4 font-bold rounded-full shadow-lg hover:scale-105 transition-transform duration-300 ease-in-out">
              Share a Ride, Save the Planet!
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default PlanetSection;

import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
import Land from './pages/Land/Land';
import Blog from './pages/Blog/Blog';
import BlogPost from './pages/Blog/BlogPost';
import Faq from './pages/Faq/Faq';
import Privacy from './pages/Privacy/Privacy';
import Terms from './pages/Terms/Terms';
import CookiePolicy from './pages/CookiePolicy/CookiePolicy';
import Header from './components/Header';
import Footer from './components/Footer';
import ContactForm from "./pages/contact/ContactForm";
import AboutUs from "./pages/AboutUs/AboutUs";

const isLocal = window.location.hostname.includes("localhost");
const isDev = window.location.hostname.includes("dev");
const initGtag = ()=>{
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-5HQVZ9BS')
}
if(!isLocal && !isDev) initGtag();

const App = () => {
    return(
        <Router>
            <ScrollToTop />
            <div className="relative min-h-screen flex flex-col bg-gray-100">
                <Header/>
                <div className="flex-1 flex flex-col h-full">
                    <Routes>
                        <Route path="/" element={<Land />} />
                        <Route path="/about" element={<AboutUs />} />
                        <Route path="/faq" element={<Faq />} />
                        <Route path="/blog" element={<Blog />} />
                        <Route path="/blog/:postSlug" element={<BlogPost />} />
                        <Route path="/terms" element={<Terms />} />,
                        <Route path="/privacy-policy" element={<Privacy />} />,
                        <Route path="/cookie-policy" element={<CookiePolicy />} />,
                        <Route path="/contact" element={<ContactForm />} />,    
                        <Route path="*" element={<Navigate to="/" />} />
                    </Routes>
                </div>
                <Footer />
            </div>
        </Router>
    )
}

export default App;

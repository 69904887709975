// BlogPost.js
import React from 'react';
import { useParams } from 'react-router-dom';
import handleOpenApp from "utils/handleOpenApp"
import posts from './posts';
import ReadNext from './ReadNext';
import data1 from 'pages/Blog/posts/1/data';
import data2 from 'pages/Blog/posts/2/data';
import data3 from 'pages/Blog/posts/3/data';
import data4 from 'pages/Blog/posts/4/data';
import data5 from 'pages/Blog/posts/5/data';
import data6 from 'pages/Blog/posts/6/data';
import data7 from 'pages/Blog/posts/7/data';
import data8 from 'pages/Blog/posts/8/data';
import data9 from 'pages/Blog/posts/9/data';
import data10 from 'pages/Blog/posts/10/data';

const datas = [
    data1,
    data2,
    data3,
    data4,
    data5,
    data6,
    data7,
    data8,
    data9,
    data10
]

const BlogPost = () => {
    const { postSlug } = useParams();
    const currentIndex = posts.findIndex(post => post.slug === postSlug);
    const data = datas[currentIndex];

    return (
        <div className="bg-gray-50 py-16 px-6 lg:px-24">
        <div className="max-w-4xl mx-auto space-y-12">
            
            {/* Cover Image */}
            {data.coverImage && (
            <img src={data.coverImage} alt="Cover" className="w-full h-auto rounded-lg mb-8" />
            )}
            
            {/* Title */}
            <h1 className="text-5xl font-extrabold text-blue-600 text-center">
            {data.title}
            </h1>

            {/* Introduction */}
            <p className="text-xl text-gray-700 text-center max-w-3xl mx-auto">
            {data.introduction.text}
            </p>

            {/* Sections */}
            {data.sections.map((section, index) => (
                <section key={index} className="space-y-6">
                    <h2 className="text-3xl font-semibold text-gray-900">
                    {section.heading}
                    </h2>
                    
                    {/* Mid-content Image (if available) */}
                    {section.midImage && (
                    <img src={section.midImage} alt="Mid-content" className="w-full h-auto rounded-lg my-8" />
                    )}
                    
                    {section.content.map((paragraph, idx) => (
                        <React.Fragment key={idx}>
                            {typeof paragraph === 'string' ? (
                            <p className="text-lg text-gray-700">{paragraph}</p>
                            ) : (
                            paragraph.type === 'list' && (
                                <ul className="list-disc list-inside space-y-4">
                                {paragraph.items.map((item, i) => (
                                    <li key={i} className="text-lg text-gray-700">
                                        {item}
                                    </li>
                                ))}
                                </ul>
                            )
                            )}
                        </React.Fragment>
                    ))}
                </section>
            ))}

            {/* Conclusion */}
            {data.conclusion && (
            <section className="space-y-6">
                <h2 className="text-3xl font-semibold text-gray-900">{data.conclusion.heading}</h2>
                <p className="text-lg text-gray-700">{data.conclusion.text}</p>
            </section>
            )}

            {/* join button */}
            <div className="text-center py-8">
                    <button className="btn btn-primary btn-lg px-8 py-4 font-bold rounded-full shadow-lg hover:scale-105 transition-transform duration-300 ease-in-out" onClick={handleOpenApp}>
                        Join Ride Share Now!
                    </button>
            </div>

            {/* Read Next Section */}
            <ReadNext currentIndex={currentIndex} />
        </div>
        </div>
    )
}

export default BlogPost;
